.sub-header {
  font-size: 24px;
  font-style: normal;
  font-weight: bold;
  padding-left: 0;
  float: left;
}

.ant-select-selector {
  height: 40px !important;
  border-radius: 0 !important;
}

.ant-select-selection-placeholder {
  text-align: left !important;
}

.divider-vertical {
  float: left;
  margin-left: 11px;
  height: 36px;
  margin-right: 4px;
  margin-top: -2px;
}

.tags-label {
  font-size: 16px;
  line-height: 24px;
  font-weight: 400;

}

.ant-drawer {
  z-index: 997;
}
.ant-input-search-button{
  height: 40px !important;
}
.ant-input-search .ant-input {
  height: 40px; 
  line-height: 40px; 
}